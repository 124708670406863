/* App.css */
.app-container {
  display: flex;
  height: 100vh;
}

.map-container {
  flex: 2; /* Reduced size */
  background-color: #1a1a2e;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Ensure it doesn’t overflow */
}


.sidebar {
  z-index: 10; /* Higher than the map-container */
  width: 250px;
  background-color: #2e2e3e;
  color: white;
  padding: 20px;
  overflow-y: auto;
}


/* App.css */
.sidebar ul li:hover {
  cursor: pointer;
  color: #ccc;
}

.validator-info {
  margin-top: 20px;
  background-color: #444;
  padding: 10px;
  border-radius: 5px;
}


/* src/styles/App.css */

.sidebar-header {
  display: flex; /* Use flexbox layout */
  align-items: center; /* Center items vertically */
  justify-content: space-between; /* Space between heading and button */
  margin-bottom: 10px; /* Space below header */
}

.clear-button {
  padding: 5px 10px; /* Smaller padding */
  background-color: #4CAF50; /* Green background */
  color: white; /* White text */
  border: none; /* Remove border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor */
  font-size: 14px; /* Smaller font size */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.clear-button:hover {
  background-color: #45a049; /* Darker green on hover */
}

.sidebar_little {
  position: fixed;
  top: 10px;
  left: 10px;
  background: rgba(46, 46, 62, 0.9); /* Matching the sidebar color theme */
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  z-index: 10;
  width: 220px; /* Keep the width consistent */
  color: white; /* Text color for readability */
}

.sidebar-title {
  color: #ffffff; /* Title color */
  margin: 0 0 5px;
  font-size: 16px; /* Adjusted font size for visibility */
  font-weight: bold; /* Make title bold */
}

.sidebar-wallet-address {
  font-size: 12px; /* Smaller wallet address */
  margin: 0;
  color: #b3b3b3; /* Distinct color for wallet address */
}

.copy-button {
  margin-top: 5px;
  padding: 5px;
  background-color: #4CAF50; /* Button color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.copy-message {
  color: green; /* Color for copy message */
  margin-top: 5px;
  font-size: 12px; /* Smaller font for the message */
}

/* App.css */

.link-line {
  stroke: #FF69B4; /* Hot pink color */
  stroke-width: 2px; /* Bold link line */
  opacity: 0.8;
  transition: stroke-width 0.3s;
}

.link-line:hover {
  stroke-width: 4px; /* Increase width on hover */
  opacity: 1;
}

.sidebar_little {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 250px;
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.sidebar-title {
  font-size: 1.2em;
  font-weight: bold;
  color: #4b0082; /* Dark purple for titles */
}

.sidebar-wallet-address {
  font-weight: bold;
  color: #FF6B6B; /* Soft red for wallet address */
}

.copy-button {
  background-color: #4CAF50; /* Green */
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  margin-top: 10px;
}

.copy-button:hover {
  background-color: #45a049;
}

.copy-message {
  color: #4CAF50;
  font-weight: bold;
  font-size: 0.9em;
}

.sidebar_little {
  background-color: #2e2e3e; /* Dark background for the sidebar tile */
  color: #ffffff; /* Set text color to white for all content inside */
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.sidebar-title {
  color: #ffffff; /* Ensure the title text is white */
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
}



